<template>
  <v-app>
    <v-snackbar v-model="alert.show" :color="alert.color" top>
      <v-row class="py-0 my-0">
        <v-col cols="12" md="11" class="py-0 my-0">
          {{ alert.message }}
        </v-col>
        <v-col cols="12" md="1" class="py-0 my-0">
          <v-icon small dark @click="alert.show = false"> {{ icons.mdiClose }} </v-icon>
        </v-col>
      </v-row>
    </v-snackbar>

    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <!-- <theme-switcher></theme-switcher> -->
          
          <app-bar-chat-notifier
            :unseenMsg="unseenMsg"
          />

          <app-bar-notification />
          <app-bar-user-menu />
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          {{ footer_txt }}
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { UPSERT_CHAT_ID, LOAD_CHAT_IDS } from "@/store/_actiontypes";
import router from "@/router/index";
import { mdiMagnify, mdiBellOutline, mdiGithub, mdiClose } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarNotification from './components/AppBarNotification.vue'
import AppBarChatNotifier from './components/AppBarChatNotifier.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import { howLongAgo, secondsTolocal } from "@/utils/dates"
import * as firestoreService from '@/database/firestore'
import * as firebaseService from '@/database/firebase'

export default {
  name: "contents",
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarNotification,
    AppBarChatNotifier,
    AppBarUserMenu,
  },

  data() {
    return {
      howLongAgo,
      secondsTolocal,
      adminId: "",
      chatId: "",
      account: "",
      photo: "",
      unseenMsg: [],
      notifications: [],
      currentRoute: "",
      timer: null,
      isDrawerOpen: true,
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiClose,
      },
      footer_txt: "",
    }
  },

  mounted(){
    // this.loadAllData();

    // if(this.$route.query.complete) this.upsertChatId();
    // if(this.$tools.getSession("chat")){
    //   this.upsertChatId(this.$tools.getSession("chat"))
    // }

    // this.timer = setInterval(() => {
    //   this.currentRoute = router.currentRoute.name;
    //   let status = this.currentRoute === 'message' ? true : false;

    //   firebaseService.updateUserOnlineStatus(this.chatId, true);
    // }, 120500)
  },

  computed: {
    ...mapState({
      user: (state) => state.account.organization,
      alert: (state) => state.alert.alert,
    }),
  },

  methods: {
    ...mapActions("account", [UPSERT_CHAT_ID, LOAD_CHAT_IDS]),

    loadAllData(){
      let chat_data = this.$tools.getChatUser();
      this.adminId = this.$tools.getEnv("VUE_APP_CHAT_ID"),
      this.chatId = chat_data.id ? chat_data.id : this.user.id;

      // this.getUnseenMsg();
    },

    upsertChatId(chat_json=null){
      if(chat_json){
        try{
          this.UPSERT_CHAT_ID({chat_id: chat_json.id}).then((res)=>{
            console.log(res)
          });
        } catch(error){
          console.log(error)
        }
        return;
      }
      setTimeout(() => {
        let chat_json = this.$tools.getSession('chat');
        this.upsertChatId(chat_json);
      }, 5000);
    },

    getUnseenMsg(){
      try{
        this.unseenMsg = [];
        firestoreService.getAllRoomsGlobal(false).then(({ data }) => {
          data.forEach(async room => {
            if(room.hasOwnProperty("users") && room.users.includes(this.chatId)){
              await firestoreService.getMessagesGlobal(room.id, 1).then(({ data }) => {
                data.forEach(message => {
                  if(!message.hasOwnProperty("seen") && this.chatId !== message.sender_id){
                    this.LOAD_CHAT_IDS({cid:message.sender_id}).then((res) => {
                      let photo = res.data.items[0]["photo"]
                        ? this.$tools.getEnv("VUE_APP_FILE_SERVER") + res.data.items[0]["photo"]
                        : this.$tools.defaultImage();
  
                      this.unseenMsg.push({
                        status: "unread",
                        roomId: room.id,
                        photo: photo,
                        userName: message.user_name,
                        time: this.howLongAgo(this.secondsTolocal(message.timestamp.seconds)),
                        content: message.content.substring(0, 30),
                      });
                    });
                  }
                });
              });
            }
          });
        });
      } catch (error){
        console.log(error)
      }
      setTimeout(() => {
        this.getUnseenMsg();
      }, 35000);
    },

  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
